import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Container, Grid, Typography, Box, RadioGroup, FormControl, FormLabel, Radio } from '@mui/material';

function JobSafetyAnalysisForm() {
    const [formData, setFormData] = useState({
        dayDate: '',
        projectNumber: '',
        projectName: '',
        dhgCompany: '',
        supervisorName: '',
        taskLocation: '',
        workActivities: {
            demoPrepWork: false,
            mechanicalDemo: false,
            explosiveDemo: false,
            longReachDemo: false,
            highWorkDemo: false,
            burningGasTorch: false,
            cuttingSaw: false,
            substructureDemo: false,
            truckLoading: false,
            concreteBreaking: false,
            rigging: false,
            equipmentInstallRemoval: false,
            vesselInstallRemoval: false,
            confinedSpaceEntry: false,
            excavation: false,
            trenching: false,
            pipeLaying: false,
            UGStructures: false,
            grading: false,
            stonePlacement: false,
            concreteForming: false,
            concretePour: false,
            abatement: false,
            universalWaste: false,
            customActivity: '', // для пользовательской активности
        },
        evaluation: {
            preJobSafetyMeeting: '',
            personalProtectiveEquipment: '',
            safetyEquipmentInspected: '',
            toolMaterialRequirements: '',
            toolInspectionRequirements: '',
            equipmentRequirements: '',
            equipmentInspection: '',
            riggingRequirements: '',
            riggingInspection: '',
            hotWorkPermit: '',
            fireExtinguisherRequired: '',
            barricadingRequired: '',
            evacuationRoutesReviewed: '',
            undergroundUtilitiesLocated: '',
            coordinationWithOtherCrews: '',
            liveSystemsReviewed: '',
            containmentRequirement: '',
            sdsReviewed: '',
            confinedSpaceAirMonitor: '',
            confinedSpacePermitRequired: '',
            trenchInspection: '',
        },
    });

    const saveFormToLocal = () => {
        let forms = JSON.parse(localStorage.getItem('forms')) || [];
        forms.push({ id: Date.now(), ...formData });
        localStorage.setItem('forms', JSON.stringify(forms));
        alert('Form saved locally!');
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [section, field] = name.split('.'); // Разделяем имя на раздел и поле

        if (section === 'workActivities') {
            setFormData((prevState) => ({
                ...prevState,
                workActivities: {
                    ...prevState.workActivities,
                    [field]: type === 'checkbox' ? checked : value,
                },
            }));
        } else if (section === 'evaluation') {
            setFormData((prevState) => ({
                ...prevState,
                evaluation: {
                    ...prevState.evaluation,
                    [field]: value,
                },
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            alert("You need to be logged in.");
            return;
        }

        fetch('https://admin.dhgriffinapp.com/api/job-safety-analysis', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => console.log('Form submitted successfully', data))
            .catch((error) => console.error('Error submitting form:', error));
    };

    return (
        <Container style={{ margin: '100px auto', padding: '20px', border: "solid 1px black", borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Grid container>
                {/* Верхняя часть */}
                <Grid container style={{  marginBottom: '20px', padding: '10px' }}>
                    {/* Логотип слева */}
                    <Grid item xs={12} sm={3} style={{ padding: '10px' }}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <img src={"https://admin.dhgriffinapp.com/build/backend/images/logo.png"} width="160px" height="160px" alt="Logo" />
                        </Box>
                    </Grid>

                    {/* Поля Project слева */}
                    <Grid item xs={12} sm={3} style={{  padding: '10px' }}>
                        <TextField
                            label="Day/Date"
                            type="date"
                            name="dayDate"
                            value={formData.dayDate}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Project Number"
                            name="projectNumber"
                            value={formData.projectNumber}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Project Name"
                            name="projectName"
                            value={formData.projectName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    {/* Заголовок по центру */}
                    <Grid item xs={12} sm={3} style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <Typography variant="h6" align="center">
                            D.H. Griffin <br /> Companies <br /> Job Safety Analysis (JSA)
                        </Typography>
                    </Grid>

                    {/* Поля справа */}
                    <Grid item xs={12} sm={3} style={{ padding: '10px' }}>
                        <TextField
                            label="DHG Company/Sub Name"
                            name="dhgCompany"
                            value={formData.dhgCompany}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Supervisor Name"
                            name="supervisorName"
                            value={formData.supervisorName}
                            onChange={handleChange}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            label="Task Location"
                            name="taskLocation"
                            value={formData.taskLocation}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {/* WORK ACTIVITIES Section */}
                <Grid item xs={12} style={{ border: '1px solid #000', padding: 0, marginBottom: '20px' }}>
                    <Typography variant="h6" style={{ backgroundColor: '#000', color: '#fff', padding: '5px' }}>Work Activities</Typography>
                    <Grid container style={{ padding: '20px' }}>
                        {Object.keys(formData.workActivities).map((activity, index) => (
                            activity !== 'customActivity' && (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={`workActivities.${activity}`}
                                                checked={formData.workActivities[activity]}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={activity.replace(/([A-Z])/g, ' $1').trim()}
                                    />
                                </Grid>
                            )
                        ))}
                        <Grid item xs={12}>
                            <TextField
                                label="Other (Describe)"
                                name="workActivities.customActivity"
                                value={formData.workActivities.customActivity}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Evaluation of Work Area Section */}
                <Grid item xs={12} style={{ border: '1px solid #000', padding: '0', marginBottom: '20px' }}>
                    <Typography variant="h6" style={{ backgroundColor: '#000', color: '#fff', padding: '5px', }}>Evaluation of Work Area</Typography>
                    <Grid container style={{ padding: '20px' }}>
                        {Object.keys(formData.evaluation).map((evaluation, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{evaluation.replace(/([A-Z])/g, ' $1').trim()}</FormLabel>
                                    <RadioGroup
                                        name={`evaluation.${evaluation}`}
                                        value={formData.evaluation[evaluation]}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="na" control={<Radio />} label="N/A" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Кнопки Submit и Save Locally */}
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                        Submit
                    </Button>
                </Grid>
                <Grid item style={{margin:"10px 0"}} xs={12}>
                    <Button variant="contained"  color="success" fullWidth onClick={saveFormToLocal}>
                        Save Locally
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default JobSafetyAnalysisForm;
