import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Modal, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SignaturePad from 'react-signature-canvas';
import { openDB } from 'idb';
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';

const DB_VERSION = 1;
const DB_NAMES = {
    INSTRUCTIONS: 'WeeklyInstructionsDB',
    WEEK_LIST: 'WeeklyListDB',
    SYNC: 'SyncDB',
    SIGNEDDOCUMENTSDB: 'SignedDocumentsDB',
};
const STORES = {
    INSTRUCTIONS: 'weeklyInstructions',
    WEEK_LIST: 'weeklyList',
    SIGNED_DOCUMENTS: 'signedDocuments',
    INSTRUCTIONS_DATA: 'instructions',
    EMPLOYEES: 'employees',
};

const getCurrentWeekNumber = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + startOfYear.getDay() + 1) / 7);
};

const fetchEmployees = async (ids) => {
    const db = await openDB(DB_NAMES.SYNC, DB_VERSION);
    const tx = db.transaction(STORES.EMPLOYEES, 'readonly');
    const store = tx.objectStore(STORES.EMPLOYEES);
    const employees = await Promise.all(ids.map((id) => store.get(id)));
    return employees.filter(Boolean);
};

const fetchInstructionText = async (instructionId, language) => {
    const db = await openDB(DB_NAMES.SYNC, DB_VERSION);
    const store = db.transaction(STORES.INSTRUCTIONS_DATA, 'readonly').objectStore(STORES.INSTRUCTIONS_DATA);
    const textData = await store.get(instructionId);
    return textData ? textData[language === 'english' ? 'textEnglish' : 'textSpanish'] : null;
};

const TBTDetail = () => {
    const { id } = useParams();
    const [instruction, setInstruction] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [textContent, setTextContent] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [openTextModal, setOpenTextModal] = useState(false);
    const [openSignModal, setOpenSignModal] = useState(false);
    const sigPadRef = useRef(null);
    const [isAllSigned, setIsAllSigned] = useState(false); // Флаг для проверки всех подписей

    useEffect(() => {
        const loadData = async () => {
            try {
                // Открываем базу данных инструкций с проверкой на создание объекта хранения
                const db = await openDB(DB_NAMES.INSTRUCTIONS, DB_VERSION, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains(STORES.INSTRUCTIONS)) {
                            db.createObjectStore(STORES.INSTRUCTIONS, { keyPath: 'id', autoIncrement: true });
                        }
                    }
                });

                if (!db.objectStoreNames.contains(STORES.INSTRUCTIONS)) {
                    console.warn("Instruction store is missing.");
                    return;
                }

                const instructionStore = db.transaction(STORES.INSTRUCTIONS, 'readwrite').objectStore(STORES.INSTRUCTIONS);
                const selectedInstruction = await instructionStore.get(Number(id));

                if (selectedInstruction) {
                    // Меняем статус на 'process', если статус был 'new'
                    if (selectedInstruction.status === 'new') {
                        selectedInstruction.status = 'process';
                        await instructionStore.put(selectedInstruction);
                    }

                    setInstruction(selectedInstruction);

                    const currentWeekNumber = getCurrentWeekNumber();

                    // Открываем базу данных для списка сотрудников с проверкой
                    const weekDb = await openDB(DB_NAMES.WEEK_LIST, DB_VERSION, {
                        upgrade(db) {
                            if (!db.objectStoreNames.contains(STORES.WEEK_LIST)) {
                                db.createObjectStore(STORES.WEEK_LIST, { keyPath: 'weekNumber' });
                            }
                        }
                    });

                    if (!weekDb.objectStoreNames.contains(STORES.WEEK_LIST)) {
                        console.warn("Weekly list store is missing.");
                        return;
                    }

                    const weekStore = weekDb.transaction(STORES.WEEK_LIST, 'readonly').objectStore(STORES.WEEK_LIST);
                    const weekList = await weekStore.get(currentWeekNumber);

                    if (weekList) {
                        const employeeData = await fetchEmployees(weekList.employees);
                        const employeesWithSignatures = await addSignaturesToEmployees(employeeData);
                        setEmployees(employeesWithSignatures);

                        // Проверяем, все ли сотрудники подписали
                        setIsAllSigned(employeesWithSignatures.every(emp => emp.signature));
                    } else {
                        alert('No weekly roster found. Set it under Administration -> Weekly Roster.');
                    }
                } else {
                    console.error('Instruction not found');
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            }
        };

        loadData();
    }, [id]);

    const selectedEmployeeName = employees.find(emp => emp.id === selectedEmployee)?.FullName || "Unknown Employee";

    const addSignaturesToEmployees = async (employeeList) => {
        const db = await openDB(DB_NAMES.SIGNEDDOCUMENTSDB, DB_VERSION, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(STORES.SIGNED_DOCUMENTS)) {
                    db.createObjectStore(STORES.SIGNED_DOCUMENTS, { keyPath: 'id', autoIncrement: true });
                }
            },
        });
        const tx = db.transaction(STORES.SIGNED_DOCUMENTS, 'readonly');
        const store = tx.objectStore(STORES.SIGNED_DOCUMENTS);

        const currentWeekNumber = getCurrentWeekNumber();

        // Получаем все документы и фильтруем по documentId
        const allDocuments = await store.getAll();
        const filteredDocuments = allDocuments.filter(doc => doc.documentId == id);
console.log(allDocuments)
console.log(filteredDocuments)
        const updatedEmployees = await Promise.all(
            employeeList.map(async (employee) => {
                const lastSignature = filteredDocuments
                    .flatMap(doc => doc.signatures || [])
                    .find(sig => sig.employeeId === employee.id && sig.weekNumber === currentWeekNumber);

                return {
                    ...employee,
                    signature: lastSignature ? lastSignature.signature : null,
                    signedAt: lastSignature ? lastSignature.signedAt : null,
                };
            })
        );

        return updatedEmployees;
    };


    const saveSignature = async () => {
        if (sigPadRef.current && instruction) {
            const signatureData = sigPadRef.current.toDataURL();

            const db = await openDB(DB_NAMES.SIGNEDDOCUMENTSDB, DB_VERSION);
            const tx = db.transaction(STORES.SIGNED_DOCUMENTS, 'readwrite');
            const store = tx.objectStore(STORES.SIGNED_DOCUMENTS);

            const currentWeekNumber = getCurrentWeekNumber();

            // Получаем все документы и фильтруем по documentId
            const allDocuments = await store.getAll();
            let existingDocument = allDocuments.find(doc => doc.documentId === instruction.uniqueId);

            const newSignature = {
                employeeId: selectedEmployee,
                signature: signatureData,
                signedAt: new Date().toISOString(),
                weekNumber: currentWeekNumber
            };

            if (existingDocument) {
                const existingSignatureIndex = existingDocument.signatures.findIndex(sig => sig.employeeId === selectedEmployee);

                if (existingSignatureIndex > -1) {
                    // Обновляем существующую подпись
                    existingDocument.signatures[existingSignatureIndex] = newSignature;
                } else {
                    // Добавляем новую подпись
                    existingDocument.signatures.push(newSignature);
                }
                await store.put(existingDocument);
            } else {
                // Создаём новый документ, если он не существует
                await store.add({
                    uniqueId: instruction.uniqueId,
                    documentId: instruction.uniqueId,
                    title: instruction.title,
                    date: instruction.date,
                    weekNumber: currentWeekNumber,
                    signatures: [newSignature],
                });
            }

            const updatedEmployees = await addSignaturesToEmployees(employees);
            setEmployees(updatedEmployees);

            // Проверка, все ли сотрудники подписали
            setIsAllSigned(updatedEmployees.every(emp => emp.signature));

            setOpenSignModal(false);
        }
    };

    const uploadSignatures = async () => {
        if (isAllSigned) {
            console.log(instruction);
            try {
                const currentWeekNumber = getCurrentWeekNumber();
                // Отправляем данные инструкции на сервер
                const response = await axiosInstance.post('/upload-signatures', {
                    instructionId: instruction.instructionId,
                    instructionUserId: instruction.uniqueId,
                    title: instruction.title,
                    date: instruction.date,
                    weekNumber: currentWeekNumber,
                    instructionDate: new Date().toISOString(), // Добавляем текущую дату
                    instructorId: localStorage.getItem('userId') || false,
                    signatures: employees.map(emp => ({
                        employeeId: emp.id,
                        signature: emp.signature,
                        signedAt: emp.signedAt,
                    })),
                });

                if (response.status === 200) {
                    console.log("Data sent to server successfully.");

                    // Обновляем статус инструкции на 'finished' после успешной отправки
                    const db = await openDB(DB_NAMES.INSTRUCTIONS, DB_VERSION);
                    const instructionStore = db.transaction(STORES.INSTRUCTIONS, 'readwrite').objectStore(STORES.INSTRUCTIONS);
                    const updatedInstruction = { ...instruction, status: 'finished' };
                    await instructionStore.put(updatedInstruction);
                    setInstruction(updatedInstruction);
                    alert('Data sent successfully');
                    navigate('/tbt');
                }
            } catch (error) {
                console.error("Error uploading signatures:", error);
                alert("Failed to upload signatures. Please try again.");
            }
        } else {
            alert("All employees need to sign the document before uploading.");
        }
    };

    const openTextContent = async (language) => {
        const text = await fetchInstructionText(instruction.instructionId, language);
        if (text) {
            setTextContent(text);
            setOpenTextModal(true);
        } else {
            console.error('Text not found');
        }
    };

    const navigate = useNavigate();

    if (!instruction) {
        return <p>Loading...</p>; // Можно заменить на индикатор загрузки
    }

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>{instruction?.title}</Typography>
            <Typography variant="h6">Status: {instruction?.status}</Typography>
            <Typography variant="h6">
                Date: {instruction?.date?.$d ? new Date(instruction.date.$d).toLocaleDateString('en-US') : 'N/A'}
            </Typography>

            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => openTextContent('english')} sx={{ mr: 2 }}>View English Text</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" onClick={() => openTextContent('spanish')}>View Spanish Text</Button>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>

                    {instruction.status !== 'finished' && (
                        <Button variant="contained" color="primary" onClick={uploadSignatures} disabled={!isAllSigned}>
                            Upload Signatures
                        </Button>
                    )}
                </Grid>
            </Grid>

            <Typography variant="h5" sx={{ mt: 3 }}>Employees List:</Typography>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Full Name</TableCell>
                            <TableCell align="center">Signature</TableCell>
                            <TableCell align="center">Date Signed</TableCell>
                            {instruction.status !== 'finished' && (
                            <TableCell align="center">Action</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map((employee) => (
                            <TableRow key={employee.id}>
                                <TableCell>{employee.FullName}</TableCell>
                                <TableCell align="center">
                                    {employee.signature ? <img src={employee.signature} alt="Signature" width="100" /> : 'No Signature'}
                                </TableCell>
                                <TableCell align="center">
                                    {employee.signedAt ? new Date(employee.signedAt).toLocaleDateString('en-US') : 'Not Signed'}
                                </TableCell>
                                {instruction.status !== 'finished' && (
                                    <TableCell align="center">
                                        <Button variant="outlined" onClick={() => { setSelectedEmployee(employee.id); setOpenSignModal(true); }}>Sign</Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openTextModal} onClose={() => setOpenTextModal(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    p: 3,
                    boxShadow: 24,
                    borderRadius: '8px',
                }}>
                    <Typography variant="h6">Instruction Text</Typography>
                    <Typography variant="body1" sx={{ mt: 2, whiteSpace: 'pre-line' }}>{textContent}</Typography>
                    <Button onClick={() => setOpenTextModal(false)} variant="contained" sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>

            <Modal open={openSignModal} onClose={() => setOpenSignModal(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '600px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    p: 3,
                    boxShadow: 24,
                    borderRadius: '8px',
                }}>
                    <Typography variant="h6" gutterBottom>Signature for Employee : <b>{selectedEmployeeName}</b> </Typography>
                    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                        <SignaturePad ref={sigPadRef} canvasProps={{ width: 566, height: 200, className: 'sigCanvas' }} />
                    </Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="contained" onClick={saveSignature}>Accept</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="outlined" onClick={() => setOpenSignModal(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    );
};

export default TBTDetail;
