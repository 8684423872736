import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Container } from '@mui/material';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        fetch('https://admin.dhgriffinapp.com/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        })
            .then(response => response.json())
            .then(data => {
                if (data.token) {
                    console.log(data);

                    // Сохраняем токен в localStorage
                    localStorage.setItem('token', data.token);

                    // Делаем запрос на получение информации о пользователе
                    fetch('https://admin.dhgriffinapp.com/api/user', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${data.token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(response => response.json())
                        .then(userData => {
                            console.log(userData);
                            // Сохраняем данные пользователя в localStorage
                            localStorage.setItem('firstName', userData.firstName);
                            localStorage.setItem('userId', userData.userId);
                            localStorage.setItem('lastName', userData.lastName);
                            localStorage.setItem('roles', JSON.stringify(userData.roles));

                            // Перенаправляем пользователя на нужную страницу
                            navigate('/main');
                        })
                        .catch(err => console.error('Error fetching user data:', err));
                } else {
                    alert('Login failed');
                }
            })
            .catch(err => console.error('Error logging in:', err));
    };

    return (
        <Container maxWidth="sm">
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Typography variant="h4" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleLogin} style={{ width: '100%' }}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '20px' }}
                    >
                        Login
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default LoginPage;
