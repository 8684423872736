import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from '../axiosInstance';
import { openDB } from 'idb';

const DB_NAME = 'SyncDB';
const DB_VERSION = 1; // Используем одну версию базы данных
const EMPLOYEE_STORE = 'employees';
const INSTRUCTION_STORE = 'instructions';

const Sync = () => {
    const [syncData, setSyncData] = useState([]);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [loadingInstructions, setLoadingInstructions] = useState(false);
    const [overallSyncTime, setOverallSyncTime] = useState(localStorage.getItem('lastOverallSyncTime') || 'Never');

    const initializeDB = async () => {
        try {
            // Инициализация базы данных с проверкой и созданием objectStore, если они не существуют
            const db = await openDB(DB_NAME, DB_VERSION, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains(EMPLOYEE_STORE)) {
                        db.createObjectStore(EMPLOYEE_STORE, { keyPath: 'id' });
                    }
                    if (!db.objectStoreNames.contains(INSTRUCTION_STORE)) {
                        db.createObjectStore(INSTRUCTION_STORE, { keyPath: 'id' });
                    }
                },
            });

            return db;
        } catch (error) {
            console.error('Error initializing DB:', error);
        }
    };

    const fetchAndStoreEmployees = async () => {
        setLoadingEmployees(true);
        try {
            const db = await initializeDB();

            const response = await axiosInstance.get('/employees');
            const employeesData = response.data;

            const tx = db.transaction(EMPLOYEE_STORE, 'readwrite');
            const store = tx.objectStore(EMPLOYEE_STORE);

            for (const employee of employeesData) {
                await store.put(employee);
            }

            await tx.done;

            const currentTime = new Date().toLocaleString();
            localStorage.setItem('lastEmployeeSyncTime', currentTime);
            updateSyncData('Employee Data', currentTime);
        } catch (error) {
            console.error('Error fetching employees:', error);
        } finally {
            setLoadingEmployees(false);
        }
    };

    const fetchAndStoreInstructions = async () => {
        setLoadingInstructions(true);
        try {
            const db = await initializeDB();

            const response = await axiosInstance.get('/instructions');
            const instructionsData = response.data;

            const tx = db.transaction(INSTRUCTION_STORE, 'readwrite');
            const store = tx.objectStore(INSTRUCTION_STORE);

            for (const instruction of instructionsData) {
                await store.put(instruction);
            }

            await tx.done;

            const currentTime = new Date().toLocaleString();
            localStorage.setItem('lastInstructionSyncTime', currentTime);
            updateSyncData('Instruction Data', currentTime);
        } catch (error) {
            console.error('Error fetching instructions:', error);
        } finally {
            setLoadingInstructions(false);
        }
    };

    const syncAllData = async () => {
        setLoadingEmployees(true);
        setLoadingInstructions(true);
        try {
            await fetchAndStoreEmployees();
            await fetchAndStoreInstructions();
            const currentTime = new Date().toLocaleString();
            localStorage.setItem('lastOverallSyncTime', currentTime);
            setOverallSyncTime(currentTime);
        } catch (error) {
            console.error('Error synchronizing all data:', error);
        } finally {
            setLoadingEmployees(false);
            setLoadingInstructions(false);
        }
    };

    const updateSyncData = (name, syncTime) => {
        setSyncData((prevData) => {
            const updatedData = prevData.map((data) =>
                data.name === name ? { ...data, syncTime } : data
            );
            return updatedData;
        });
    };

    useEffect(() => {
        const loadDataFromLocalStorage = () => {
            const employeeSyncTime = localStorage.getItem('lastEmployeeSyncTime') || 'Never';
            const instructionSyncTime = localStorage.getItem('lastInstructionSyncTime') || 'Never';

            setSyncData([
                { id: 1, name: 'Employee Data', syncTime: employeeSyncTime },
                { id: 2, name: 'Instruction Data', syncTime: instructionSyncTime },
            ]);
        };

        loadDataFromLocalStorage();
    }, []);

    const columns = [
        { field: 'name', headerName: 'Data Type', flex: 1 },
        { field: 'syncTime', headerName: 'Last Sync Time', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <Box display="flex" justifyContent="center" alignItems="center">
                    {params.row.name === 'Employee Data' && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={fetchAndStoreEmployees}
                            disabled={loadingEmployees}
                            sx={{ mt: 1 }}
                        >
                            {loadingEmployees ? 'Syncing...' : 'Sync Employees'}
                        </Button>
                    )}
                    {params.row.name === 'Instruction Data' && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={fetchAndStoreInstructions}
                            disabled={loadingInstructions}
                            sx={{ mt: 1 }}
                        >
                            {loadingInstructions ? 'Syncing...' : 'Sync TBT Instructions'}
                        </Button>
                    )}
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Data Synchronization
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: 'gray' }}>
                Last overall synchronization: <strong>{overallSyncTime}</strong>
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={syncAllData}
                disabled={loadingEmployees || loadingInstructions}
                sx={{ mb: 3 }}
            >
                {loadingEmployees || loadingInstructions ? 'Syncing All...' : 'Sync All Data'}
            </Button>
            <Box
                sx={{
                    height: 400,
                    width: '100%',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 3,
                    p: 2,
                }}
            >
                <DataGrid
                    rows={syncData}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </Box>
        </Box>
    );
};

export default Sync;
