import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Send as SendIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function SavedForms() {
    const [savedForms, setSavedForms] = useState([]);
    const navigate = useNavigate();

    // Подгружаем данные из localStorage
    useEffect(() => {
        const forms = JSON.parse(localStorage.getItem('forms')) || [];
        setSavedForms(forms);
    }, []);

    // Удаление формы из localStorage
    const handleDelete = (id) => {
        const updatedForms = savedForms.filter((form) => form.id !== id);
        setSavedForms(updatedForms);
        localStorage.setItem('forms', JSON.stringify(updatedForms));
    };

    // Редактирование формы
    const handleEdit = (id) => {
        navigate(`/edit/${id}`); // Перенаправляем на страницу редактирования с id
    };

    // Отправка формы на сервер
    const handleSubmit = (form) => {
        const token = localStorage.getItem('token');

        fetch('https://admin.dhgriffinapp.com/api/job-safety-analysis', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(form),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Form submitted successfully:', data);
                // Удаляем форму из localStorage после успешной отправки
                handleDelete(form.id);
            })
            .catch((error) => console.error('Error submitting form:', error));
    };

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Day/Date</TableCell>
                        <TableCell>Project Number</TableCell>
                        <TableCell>Project Name</TableCell>
                        <TableCell>Supervisor Name</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {savedForms.map((form) => (
                        <TableRow key={form.id}>
                            <TableCell>{form.dayDate}</TableCell>
                            <TableCell>{form.projectNumber}</TableCell>
                            <TableCell>{form.projectName}</TableCell>
                            <TableCell>{form.supervisorName}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleEdit(form.id)} color="primary">
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(form.id)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton onClick={() => handleSubmit(form)} color="success">
                                    <SendIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SavedForms;
