import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import DocumentIcon from '@mui/icons-material/Description';
import ChecklistIcon from '@mui/icons-material/CheckCircle';
import NotesIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';

const options = [
    { name: 'TBT', icon: <DocumentIcon fontSize="large" />, link: '/#/tbt' },
    { name: 'CC', icon: <ChecklistIcon fontSize="large" />, link: '/#/cc' },
    { name: 'PO', icon: <NotesIcon fontSize="large" />, link: '/#/po' },
    { name: 'JSA', icon: <WorkIcon fontSize="large" />, link: '/#/jsa' },
];

const MainPage = () => {
    return (
        <Container maxWidth={false} style={{ padding: '20px', width: '100%' }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {options.map((option, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <a href={option.link} style={{ textDecoration: 'none' }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '200px',
                                    backgroundColor: '#f0f4f8',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '12px',
                                    transition: 'transform 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Icon sx={{ fontSize: 50, color: '#1976d2' }}>
                                        {option.icon}
                                    </Icon>
                                    <Typography variant="h6" sx={{ marginTop: '10px', color: '#000' }}>
                                        {option.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </a>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default MainPage;
