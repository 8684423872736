import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Checkbox } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { openDB } from 'idb';
import { getISOWeek, formatISO } from 'date-fns';

// Основная база данных для сотрудников
const EMPLOYEE_DB_NAME = 'SyncDB';
const EMPLOYEE_DB_VERSION = 1;
const EMPLOYEE_STORE = 'employees';

// База данных для еженедельного списка
const WEEKLY_LIST_DB_NAME = 'WeeklyListDB';
const WEEKLY_LIST_DB_VERSION = 1;
const WEEKLY_LIST_STORE = 'weeklyList';

const WeeklyEmployeeComponent = () => {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [currentWeekNumber, setCurrentWeekNumber] = useState(getISOWeek(new Date()));

    const initializeEmployeeDB = async () => {
        try {
            const db = await openDB(EMPLOYEE_DB_NAME, EMPLOYEE_DB_VERSION, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains(EMPLOYEE_STORE)) {
                        db.createObjectStore(EMPLOYEE_STORE, { keyPath: 'id' });
                    }
                },
            });
            return db;
        } catch (error) {
            console.error('Error initializing Employee DB:', error);
            return null;
        }
    };

    const initializeWeeklyListDB = async () => {
        try {
            const db = await openDB(WEEKLY_LIST_DB_NAME, WEEKLY_LIST_DB_VERSION, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains(WEEKLY_LIST_STORE)) {
                        db.createObjectStore(WEEKLY_LIST_STORE, { keyPath: 'weekNumber' });
                    }
                },
            });
            return db;
        } catch (error) {
            console.error('Error initializing Weekly List DB:', error);
            return null;
        }
    };

    useEffect(() => {
        const loadData = async () => {
            const employeeDB = await initializeEmployeeDB();
            const weeklyListDB = await initializeWeeklyListDB();

            if (employeeDB) {
                // Загружаем сотрудников из основной базы данных
                const tx = employeeDB.transaction(EMPLOYEE_STORE, 'readonly');
                const store = tx.objectStore(EMPLOYEE_STORE);
                const allEmployees = await store.getAll();
                setEmployees(allEmployees);
            }

            if (weeklyListDB) {
                // Загружаем список сотрудников для текущей недели, если он существует
                const weeklyTx = weeklyListDB.transaction(WEEKLY_LIST_STORE, 'readonly');
                const weeklyStore = weeklyTx.objectStore(WEEKLY_LIST_STORE);
                const currentList = await weeklyStore.get(currentWeekNumber);

                if (currentList) {
                    setSelectedEmployees(currentList.employees);
                } else {
                    setSelectedEmployees([]);
                }
            }
        };

        loadData();
    }, [currentWeekNumber]);

    const saveWeeklyList = async () => {
        try {
            const weeklyListDB = await initializeWeeklyListDB();

            if (weeklyListDB) {
                const tx = weeklyListDB.transaction(WEEKLY_LIST_STORE, 'readwrite');
                const store = tx.objectStore(WEEKLY_LIST_STORE);

                const weeklyData = {
                    weekNumber: currentWeekNumber,
                    employees: selectedEmployees,
                    dateCreated: formatISO(new Date()),
                };

                await store.put(weeklyData);
                alert('Weekly list saved!');
            } else {
                console.error(`Failed to initialize Weekly List DB.`);
            }
        } catch (error) {
            console.error('Error saving weekly list:', error);
        }
    };

    const handleCheckboxChange = (id) => {
        setSelectedEmployees((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((empId) => empId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const handleRemoveEmployee = (id) => {
        setSelectedEmployees((prevSelected) => prevSelected.filter((empId) => empId !== id));
    };

    const handleLoadPreviousWeek = async () => {
        try {
            const previousWeekNumber = currentWeekNumber - 1;
            const weeklyListDB = await initializeWeeklyListDB();

            if (weeklyListDB) {
                const tx = weeklyListDB.transaction(WEEKLY_LIST_STORE, 'readonly');
                const store = tx.objectStore(WEEKLY_LIST_STORE);
                const previousList = await store.get(previousWeekNumber);

                if (previousList) {
                    setSelectedEmployees(previousList.employees);
                } else {
                    alert('No list found for the previous week.');
                }
            } else {
                console.error(`Failed to initialize Weekly List DB.`);
            }
        } catch (error) {
            console.error('Error loading previous week:', error);
        }
    };

    const columns = [
        {
            field: 'select',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={selectedEmployees.includes(params.row.id)}
                    onChange={() => handleCheckboxChange(params.row.id)}
                    sx={{
                        '&.Mui-checked': {
                            color: 'blue',
                        },
                    }}
                />
            ),
        },
        { field: 'EmployeeId', headerName: 'Employee ID', width: 150 },
        { field: 'FullName', headerName: 'Full Name', flex: 1 },
        { field: 'CompanyCode', headerName: 'Company Code', width: 150 },
        { field: 'Division', headerName: 'Division', width: 150 },
        { field: 'JobClassification', headerName: 'Job Classification', flex: 1 },
    ];

    const selectedColumns = [
        { field: 'EmployeeId', headerName: 'Employee ID', width: 150 },
        { field: 'FullName', headerName: 'Full Name', flex: 1 },
        { field: 'CompanyCode', headerName: 'Company Code', width: 150 },
        { field: 'Division', headerName: 'Division', width: 150 },
        { field: 'JobClassification', headerName: 'Job Classification', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveEmployee(params.row.id)}
                >
                    Remove
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Weekly Employee Assignment
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mb: 2 }}
                onClick={saveWeeklyList}
            >
                Save Weekly List
            </Button>
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    color="secondary"*/}
            {/*    sx={{ ml: 2, mb: 2 }}*/}
            {/*    onClick={handleLoadPreviousWeek}*/}
            {/*>*/}
            {/*    Load Previous Week*/}
            {/*</Button>*/}
            <Box
                sx={{
                    height: 500,
                    width: '100%',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 3,
                    p: 2,
                    mb: 4,
                }}
            >
                <DataGrid
                    rows={employees}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                    getRowClassName={(params) =>
                        selectedEmployees.includes(params.id) ? 'selected-row' : ''
                    }
                    sx={{
                        '& .selected-row': {
                            backgroundColor: '#1976d2 !important',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#1565c0 !important',
                            },
                            '& .MuiDataGrid-cell': {
                                color: '#fff',
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        },
                    }}
                />
            </Box>

            <Typography variant="h5" gutterBottom>
                Selected Employees (Week {currentWeekNumber})
            </Typography>
            <Box
                sx={{
                    height: 300,
                    width: '100%',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 3,
                    p: 2,
                }}
            >
                <DataGrid
                    rows={employees.filter((emp) => selectedEmployees.includes(emp.id))}
                    columns={selectedColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection={false}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#e0f7fa',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default WeeklyEmployeeComponent;
