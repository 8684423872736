import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from './components/Header';
import JobSafetyAnalysisForm from './components/JobSafetyAnalysisForm';
import SavedForms from './components/SavedForms';
import SubmittedForms from './components/SubmittedForms';
import EditForm from './components/EditForm';
import LoginPage from './components/LoginPage';
import MainPage from "./components/MainPage";
import TBTPage from "./components/TBTPage";
import TBTDetail from "./components/TBTDetail";
import Sync from "./components/Sync";
import WeeklyEmployeeComponent from "./components/WeeklyEmployeeComponent";
import TBTAdd from "./components/TBTAdd";

function App() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const checkAuthentication = () => {
            const token = localStorage.getItem('token');

            // Проверяем наличие токена
            if (token) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                // Если нет токена и есть подключение к интернету, переходим на логин
                if (navigator.onLine) {
                    navigate('#/login');
                }
            }
        };

        // Проверка при загрузке
        checkAuthentication();

        // Подписываемся на событие изменения подключения к интернету
        window.addEventListener('online', checkAuthentication);
        window.addEventListener('offline', checkAuthentication);

        // Очищаем подписки при размонтировании компонента
        return () => {
            window.removeEventListener('online', checkAuthentication);
            window.removeEventListener('offline', checkAuthentication);
        };
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Удаляем токен
        localStorage.removeItem('refresh_token'); // Удаляем refresh токен
        setIsAuthenticated(false);
        navigate('#/login'); // Перенаправляем на страницу логина
    };

    return (
        <>
            {isAuthenticated && (
                <Header handleLogout={handleLogout} />
            )}

            {/* Отступ для содержимого */}
            <Container style={{ marginTop: isAuthenticated ? '80px' : '0' }}>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    {isAuthenticated ? (
                        <>
                            {/* jsa */}
                            <Route path="/jsa" element={<JobSafetyAnalysisForm />} />
                            <Route path="/jsa/saved" element={<SavedForms />} />
                            <Route path="/jsa/submitted" element={<SubmittedForms />} />
                            <Route path="/jsa/edit/:id" element={<EditForm />} />
                            {/* tbt */}
                            <Route path="/tbt" element={<TBTPage />} />
                            <Route path="/tbt/add" element={<TBTAdd />} />
                            <Route path="/tbt/:id" element={<TBTDetail />} />

                            {/* Основные страницы */}
                            <Route path="/cc" element={<div>CC Page</div>} />
                            <Route path="/po" element={<div>PO Page</div>} />
                            <Route path="/main" element={<MainPage />} />

                            {/* Другие маршруты */}
                            <Route path="/admin/synchronization" element={<Sync />} />
                            <Route path="/admin/weekly-employee" element={<WeeklyEmployeeComponent />} />
                        </>
                    ) : (
                        <Route path="*" element={<LoginPage />} />
                    )}
                </Routes>
            </Container>
        </>
    );
}

export default App;
