import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import { openDB } from 'idb';
import { useNavigate } from 'react-router-dom';

const INSTRUCTIONS_DB_NAME = 'WeeklyInstructionsDB';
const LIST_DB_NAME = 'WeeklyListDB';
const SIGNED_DOCS_DB_NAME = 'SignedDocumentsDB';
const INSTRUCTIONS_STORE = 'weeklyInstructions';
const LIST_STORE = 'weeklyList';
const SIGNED_DOCS_STORE = 'signedDocuments';

const DB_VERSION = 1;

const TBTPage = () => {
    const [instructions, setInstructions] = useState([]);
    const [allSignedStatus, setAllSignedStatus] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWeeklyInstructions = async () => {
            const db = await openDB(INSTRUCTIONS_DB_NAME, DB_VERSION, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains(INSTRUCTIONS_STORE)) {
                        db.createObjectStore(INSTRUCTIONS_STORE, { keyPath: 'id', autoIncrement: true });
                    }
                },
            });

            const tx = db.transaction(INSTRUCTIONS_STORE, 'readonly');
            const store = tx.objectStore(INSTRUCTIONS_STORE);
            const allInstructions = await store.getAll();

            const formattedInstructions = allInstructions.map((instruction) => ({
                ...instruction,
                date: instruction.date ? new Date(instruction.date.$y, instruction.date.$M, instruction.date.$D).toLocaleDateString('en-US') : 'N/A',
                uniqueId: instruction.uniqueId
            }));
            setInstructions(formattedInstructions);
        };

        fetchWeeklyInstructions();
    }, []);


    useEffect(() => {
        const checkAllSigned = async () => {
            try {
                const listDb = await openDB(LIST_DB_NAME, DB_VERSION, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains(LIST_STORE)) {
                            db.createObjectStore(LIST_STORE, { keyPath: 'id', autoIncrement: true });
                        }
                    }
                });

                const signedDocsDb = await openDB(SIGNED_DOCS_DB_NAME, DB_VERSION, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains(SIGNED_DOCS_STORE)) {
                            db.createObjectStore(SIGNED_DOCS_STORE, { keyPath: 'id', autoIncrement: true });
                        }
                    }
                });

                // Проверка существования хранилищ
                if (!listDb.objectStoreNames.contains(LIST_STORE) || !signedDocsDb.objectStoreNames.contains(SIGNED_DOCS_STORE)) {
                    console.warn("One of the required object stores does not exist.");
                    setAllSignedStatus({}); // Обнуляем статус
                    return;
                }

                // Получаем все подписанные документы из базы
                const signedDocsTx = signedDocsDb.transaction(SIGNED_DOCS_STORE, 'readonly');
                const signedDocsStore = signedDocsTx.objectStore(SIGNED_DOCS_STORE);
                const allSignedDocuments = await signedDocsStore.getAll();

                const updatedStatus = {};

                for (const instruction of instructions) {
                    // Получаем список сотрудников на текущую неделю
                    const listTx = listDb.transaction(LIST_STORE, 'readonly');
                    const listStore = listTx.objectStore(LIST_STORE);
                    const weekList = await listStore.get(instruction.weekNumber);

                    if (!weekList) {
                        updatedStatus[instruction.uniqueId] = false;
                        continue;
                    }

                    // Фильтруем только те подписи, которые соответствуют текущей инструкции и неделе
                    const currentSignatures = allSignedDocuments.filter(doc =>
                        doc.documentId === instruction.uniqueId &&
                        doc.weekNumber === instruction.weekNumber
                    );

                    // Если currentSignatures — массив объектов, содержащий подписи, извлекаем их
                    const allSignatures = currentSignatures.flatMap(doc => doc.signatures || []);

                    const allSigned = weekList.employees.every(employeeId =>
                        allSignatures.some(signature => Number(signature.employeeId) === Number(employeeId))
                    );

                    updatedStatus[instruction.uniqueId] = allSigned;
                }

                setAllSignedStatus(updatedStatus);

            } catch (error) {
                console.error("Error in checkAllSigned:", error);
            }
        };

        if (instructions.length > 0) {
            checkAllSigned();
        }
    }, [instructions]);


    const handleDelete = async (id) => {
        const db = await openDB(INSTRUCTIONS_DB_NAME, DB_VERSION);
        const tx = db.transaction(INSTRUCTIONS_STORE, 'readwrite');
        const store = tx.objectStore(INSTRUCTIONS_STORE);

        await store.delete(id);
        setInstructions((prevInstructions) => prevInstructions.filter((inst) => inst.id !== id));
    };

    const handleViewDetail = (id) => {
        navigate(`/tbt/${id}`);
    };

    const handleSend = (id) => {
        console.log(`Sending instruction with ID ${id} to the server.`);
    };

    const columns = [
        { field: 'uniqueId', headerName: 'ID', width: 70 },
        { field: 'title', headerName: 'Title', flex: 1 },
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'status', headerName: 'Status', width: 100 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <>
                    <Tooltip title="View">
                        <IconButton onClick={() => handleViewDetail(params.row.uniqueId)}>
                            <VisibilityIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    {['new', 'in progress'].includes(params.row.status) && (
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(params.row.id)} color="secondary">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {params.row.status === 'process' && allSignedStatus[params.row.uniqueId] && (
                        <Tooltip title="Send">
                            <IconButton onClick={() => handleSend(params.row.uniqueId)} color="success">
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
        },
    ];

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Weekly TBT Instructions
            </Typography>
            <Box
                sx={{
                    height: 400,
                    width: '100%',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 3,
                    p: 2,
                }}
            >
                <DataGrid
                    rows={instructions}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    getRowId={(row) => row.uniqueId}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#e0f7fa',
                        },
                        '& .MuiButton-root': {
                            textTransform: 'none',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default TBTPage;
