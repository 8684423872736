import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    MenuItem,
    MenuList,
    Popper,
    Paper,
    ClickAwayListener,
    Grow,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu
} from '@mui/material';
import {Link, Route, useLocation} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SyncIcon from '@mui/icons-material/Sync';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import WeeklyEmployeeComponent from "./WeeklyEmployeeComponent";
import { openDB } from 'idb';
const Header = ({ handleLogout, syncEmployees }) => {
    const formatSyncTime = (time) => {
        if (!time || time === 'Never') return 'Never';
        const date = new Date(time);
        return date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });
    };

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [subMenuAnchor, setSubMenuAnchor] = useState(null);
    const [adminMenuAnchor, setAdminMenuAnchor] = useState(null);
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const [lastSyncTime, setLastSyncTime] = useState(formatSyncTime(localStorage.getItem('lastSyncTime')));
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [processedCount, setProcessedCount] = useState(0);

    const location = useLocation(); // Для определения активного маршрута
    const user = {
        firstName: localStorage.getItem('firstName') || 'Unknown',
        lastName: localStorage.getItem('lastName') || 'User',
        role: localStorage.getItem('role') || 'Unknown'
    };

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        const syncTime = localStorage.getItem('lastOverallSyncTime') || 'Never';
        setLastSyncTime(formatSyncTime(syncTime));

        // Получение количества заявок в статусе processed из локальной базы данных
        const fetchProcessedCount = async () => {
            try {
                // Открываем базу данных
                const db = await openDB('WeeklyInstructionsDB', 1, {
                    upgrade(db) {
                        // Создаем хранилище данных, если его нет
                        if (!db.objectStoreNames.contains('weeklyInstructions')) {
                            db.createObjectStore('weeklyInstructions', { keyPath: 'uniqueId', autoIncrement: true });
                        }
                    }
                });

                // Проверяем, существует ли хранилище weeklyInstructions
                if (db.objectStoreNames.contains('weeklyInstructions')) {
                    const tx = db.transaction('weeklyInstructions', 'readonly');
                    const store = tx.objectStore('weeklyInstructions');
                    const allItems = await store.getAll();
                    const count = allItems.filter(item => item.status === 'process').length;
                    setProcessedCount(count);
                } else {
                    // Если хранилища нет, устанавливаем счетчик в 0
                    setProcessedCount(0);
                }

            } catch (error) {
                console.error('Ошибка при получении данных из IDB:', error);
                setProcessedCount(0); // На случай ошибки устанавливаем счетчик в 0
            }
        };
        fetchProcessedCount();
    }, []);

    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget);
        setActiveSubMenu(null);
    };

    const handleSubMenuOpen = (event, subMenu) => {
        setActiveSubMenu(subMenu);
        setSubMenuAnchor(event.currentTarget);
        setAdminMenuAnchor(null);
    };

    const handleAdminMenuOpen = (event) => {
        setActiveSubMenu('admin');
        setAdminMenuAnchor(event.currentTarget);
        setSubMenuAnchor(null);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
        setSubMenuAnchor(null);
        setAdminMenuAnchor(null);
        setActiveSubMenu(null);
    };

    const handleUserMenuOpen = (event) => {
        setUserMenuAnchor(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#1976d2' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* Логотип и бургер-меню */}
                <Box display="flex" alignItems="center">
                    <img src="/logo.png" alt="Logo" style={{ maxHeight: '40px', marginRight: '15px' }} />
                    <IconButton
                        color="inherit"
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Popper
                        open={Boolean(menuAnchor)}
                        anchorEl={menuAnchor}
                        role={undefined}
                        transition
                        disablePortal
                        style={{ zIndex: 1300 }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleMenuClose}>
                                        <MenuList autoFocusItem={Boolean(menuAnchor)}>

                                            <MenuItem to={'/main'} component={Link}  selected={location.pathname === '/main'}>
                                                <ListItemIcon>
                                                    <AssignmentIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Main</ListItemText>

                                            </MenuItem>
                                            <Divider />

                                            {/* Administration Submenu */}
                                            <MenuItem
                                                onMouseEnter={handleAdminMenuOpen}
                                                sx={{ display: 'flex', justifyContent: 'space-between' }}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <AdminPanelSettingsIcon fontSize="small" />
                                                    <ListItemText sx={{ marginLeft: '10px' }}>Administration</ListItemText>
                                                </Box>
                                                <ArrowRightIcon fontSize="small" />

                                            </MenuItem>
                                            <Divider />
                                            <Popper
                                                open={activeSubMenu === 'admin'}
                                                anchorEl={adminMenuAnchor}
                                                placement="right-start"
                                                disablePortal
                                            >
                                                <Paper>
                                                    <MenuList>
                                                        <MenuItem component={Link} to="/admin/synchronization" onClick={handleMenuClose}>Synchronization</MenuItem>
                                                        <Divider/>
                                                        <MenuItem component={Link} to="/admin/weekly-employee" onClick={handleMenuClose}>Weekly Roster</MenuItem>
                                                    </MenuList>
                                                </Paper>
                                            </Popper>



                                            {/* TBT Submenu */}
                                            <MenuItem
                                                onMouseEnter={(e) => handleSubMenuOpen(e, 'tbt')}
                                                sx={{ display: 'flex', justifyContent: 'space-between' }}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <WorkOutlineIcon fontSize="small" />
                                                    <ListItemText sx={{ marginLeft: '10px' }}>TBT</ListItemText>
                                                </Box>
                                                <ArrowRightIcon fontSize="small" />
                                            </MenuItem>
                                            <Divider sx={{ margin: '0' }} />
                                            <Popper
                                                open={activeSubMenu === 'tbt'}
                                                anchorEl={subMenuAnchor}
                                                placement="right-start"
                                                disablePortal
                                            >
                                                <Paper>
                                                    <MenuList>
                                                        <MenuItem component={Link} to="/tbt" onClick={handleMenuClose}>TBT</MenuItem>
                                                        <Divider/>
                                                        <MenuItem component={Link} to="/tbt/add" onClick={handleMenuClose}>Create new TBT</MenuItem>
                                                    </MenuList>
                                                </Paper>
                                            </Popper>


                                            {/* JSA Submenu */}
                                            <MenuItem
                                                onMouseEnter={(e) => handleSubMenuOpen(e, 'jsa')}
                                                sx={{ display: 'flex', justifyContent: 'space-between' }}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <WorkOutlineIcon fontSize="small" />
                                                    <ListItemText sx={{ marginLeft: '10px' }}>JSA</ListItemText>
                                                </Box>
                                                <ArrowRightIcon fontSize="small" />
                                            </MenuItem>
                                            <Divider />
                                            <Popper
                                                open={activeSubMenu === 'jsa'}
                                                anchorEl={subMenuAnchor}
                                                placement="right-start"
                                                disablePortal
                                            >
                                                <Paper>
                                                    <MenuList>
                                                        <MenuItem component={Link} to="/jsa/new" onClick={handleMenuClose}>Create New Form</MenuItem>
                                                        <Divider/>
                                                        <MenuItem component={Link} to="/jsa/saved" onClick={handleMenuClose}>Saved Forms</MenuItem>
                                                        <Divider/>
                                                        <MenuItem component={Link} to="/jsa/submitted" onClick={handleMenuClose}>Submitted Forms</MenuItem>
                                                    </MenuList>
                                                </Paper>
                                            </Popper>

                                            <MenuItem to={'/cc'} component={Link}  selected={location.pathname === '/cc'}>
                                                <ListItemIcon>
                                                    <AssignmentIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>CC</ListItemText>

                                            </MenuItem>
                                            <Divider />

                                            <MenuItem to={'/po'} component={Link}  selected={location.pathname === '/po'}>
                                                <ListItemIcon>
                                                    <AssignmentIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>CC</ListItemText>
                                            </MenuItem>

                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>

                {/* Статус подключения и синхронизации */}
                <Box display="flex" alignItems="center" gap={2}>

                    <Box display="flex" alignItems="center" sx={{ backgroundColor: '#f5f5f5', padding: '5px 10px', borderRadius: '20px' }}>
                        <PersonIcon fontSize="small" sx={{ marginRight: '5px', color: '#393939' }} />
                        <Typography variant="body2" color="#393939">{user.firstName} {user.lastName}</Typography>
                    </Box>

                    <Box display="flex" alignItems="center" sx={{ backgroundColor: '#f5f5f5', padding: '5px 10px', borderRadius: '20px' }}>
                        {isOnline ? (
                            <>
                                <WifiIcon color="success" sx={{ marginRight: '5px' }} />
                                <Typography variant="body2" color="green">Online</Typography>
                            </>
                        ) : (
                            <>
                                <WifiOffIcon color="error" sx={{ marginRight: '5px' }} />
                                <Typography variant="body2" color="red">Offline</Typography>
                            </>
                        )}
                    </Box>

                    <Box display="flex" alignItems="center" sx={{ backgroundColor: '#f5f5f5', padding: '5px 10px', borderRadius: '20px' }}>
                        <SyncIcon fontSize="small" sx={{ marginRight: '5px', color: 'blue' }} />
                        <Typography variant="body2" color="blue">{lastSyncTime}</Typography>
                    </Box>

                    <Box display="flex" alignItems="center" sx={{ backgroundColor: '#f5f5f5', padding: '5px 10px', borderRadius: '20px' }}>
                        <AssignmentIcon fontSize="small" sx={{ marginRight: '5px', color: 'purple' }} />
                        <Typography variant="body2" color="purple">{processedCount} Processed</Typography>
                    </Box>

                    <IconButton color="inherit" onClick={handleUserMenuOpen}>
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        anchorEl={userMenuAnchor}
                        open={Boolean(userMenuAnchor)}
                        onClose={handleUserMenuClose}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
