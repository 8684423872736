import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Modal, Box, TextField, Autocomplete } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { openDB } from 'idb';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from 'react-router-dom';

const DB_NAME = 'WeeklyInstructionsDB';
const DB_VERSION = 1;
const OBJECT_STORE_NAME = 'weeklyInstructions';
const getCurrentWeekNumber = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + startOfYear.getDay() + 1) / 7);
};
const TBTAdd = () => {

    const navigate = useNavigate();

    const [instructions, setInstructions] = useState([]);
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        const fetchInstructions = async () => {
            const db = await openDB('SyncDB', 1);
            const tx = db.transaction('instructions', 'readonly');
            const store = tx.objectStore('instructions');
            const allInstructions = await store.getAll();
            setInstructions(
                allInstructions.map((instruction) => ({
                    ...instruction,
                }))
            );
        };

        fetchInstructions();
    }, []);

    const handleViewContent = (language) => {
        if (selectedInstruction) {
            setModalContent(language === 'english' ? selectedInstruction.textEnglish : selectedInstruction.textSpanish);
            setModalOpen(true);
        }
    };

    const handleSave = async () => {
        if (!selectedInstruction || !selectedDate) {
            alert('Please select an instruction and a date');
            return;
        }

        const db = await openDB(DB_NAME, DB_VERSION, {
            upgrade(db) {
                if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
                    db.createObjectStore(OBJECT_STORE_NAME, { keyPath: 'uniqueId', autoIncrement: true });
                }
            },
        });

        const tx = db.transaction(OBJECT_STORE_NAME, 'readwrite');
        const store = tx.objectStore(OBJECT_STORE_NAME);
        const currentWeekNumber = getCurrentWeekNumber();
        const newInstruction = {
            title: selectedInstruction.Title,
            instructionId: selectedInstruction.id, // ID инструкции
            date: selectedDate,
            weekNumber:currentWeekNumber,
            status: 'new',
            signedEmployees: [], // пустой массив для подписей сотрудников
        };

        await store.add(newInstruction); // Используем add вместо put для создания новой записи каждый раз
        alert('Instruction saved successfully');
        setSelectedInstruction(null);
        setSelectedDate(null);

        navigate('/tbt');


    };

    return (
        <Container maxWidth="sm" sx={{ padding: '16px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Typography variant="h5" gutterBottom>
                Create Instruction
            </Typography>

            <Autocomplete
                options={instructions}
                getOptionLabel={(option) => option.Title || 'Unnamed'}
                onChange={(event, newValue) => setSelectedInstruction(newValue)}
                value={selectedInstruction}
                renderInput={(params) => <TextField {...params} label="Select an Instruction" fullWidth required />}
                sx={{ mb: 2 }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button variant="outlined" onClick={() => handleViewContent('english')} disabled={!selectedInstruction}>
                    View Text (ENG)
                </Button>
                <Button variant="outlined" onClick={() => handleViewContent('spanish')} disabled={!selectedInstruction}>
                    View Text (SPA)
                </Button>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    label="Select Date"
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            required
                            onClick={(e) => params.inputProps.onClick(e)} // Ensures the calendar opens on input click
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: '56px',
                                    fontSize: '1.2rem',
                                },
                                '& .MuiInputAdornment-root': {
                                    marginRight: '8px',
                                },
                            }}
                        />
                    )}
                    inputFormat="MM/DD/YYYY"
                />
            </LocalizationProvider>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                fullWidth
                sx={{ mt: 2 }}
                disabled={!selectedInstruction || !selectedDate} // Disable button until all fields are filled
            >
                Save Instruction
            </Button>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90vw',
                    maxWidth: '600px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    p: 3,
                    boxShadow: 24,
                    borderRadius: '8px',
                }}>
                    <Typography variant="h6" gutterBottom>
                        {selectedInstruction ? selectedInstruction.Title : 'Instruction Text'}
                    </Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                        {modalContent}
                    </Typography>
                    <Button onClick={() => setModalOpen(false)} sx={{ mt: 2 }} fullWidth variant="outlined">
                        Close
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default TBTAdd;
